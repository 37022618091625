@use '@angular/material' as mat;
@use 'styles/_theme-colors' as theme;

html {
	color-scheme: light;
	@include mat.theme((
		color: (
			primary: theme.$primary-palette,
			tertiary: theme.$tertiary-palette,
			theme-type: light
		),
		typography: Inter,
		density: 0
	));
}

$default-container-shape: 6px;

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
	@include mat.button-overrides((
		filled-container-shape: $default-container-shape,
		outlined-container-shape: $default-container-shape,
		protected-container-shape: $default-container-shape,
		text-container-shape: $default-container-shape
	));

	@include mat.dialog-overrides((
		container-shape: $default-container-shape,
	));

	@include mat.theme-overrides((
		surface: white,
		surface-container: white,
		on-surface: #475467,
		outline: var(--color-border)
	));
}
