:root {
	--color-main-bg: #f8fbff;
	--color-window-bg: white;
	--color-border: #EAECF0;
	--color-badge: #EDF2FF;
	--color-badge-font: #2A47C9;
	--color-badge-border: #B2BEF8;
	--color-fg-brand-primary: #5471F1;
	--color-text-tertiary: #475467;
	--color-utility-pink: #B94581;
	--color-utility-indigo: #E0EAFF;
	--color-utility-gray: #F9FAFB;
	--color-brand-primary: #1C1767;
	--color-text-primary: #101828;
}

body {
	margin: 0;
	font-family: Inter, "Helvetica Neue", sans-serif;
	background-color: var(--color-main-bg);
	color: var(--mat-sys-on-surface);
}

h1, h2, h3, h4 {
	font-weight: 600;
}

h1 {
	font-size: 1.5rem;
	color: var(--color-text-primary);
}

h2 {
	font-size: 1rem;
	color: var(--color-text-primary);
}

h3 {
	font-size: 1rem;
	color: var(--color-text-tertiary);
}

a {
	text-decoration: none;
	color: var(--color-fg-brand-primary);
	transition: all 150ms linear;

	&:hover {
		color: var(--color-brand-primary);
	}
}

button {
	transition: all 150ms linear;
}

.big-white-button, .big-blue-button, .big-pink-button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	box-shadow: 0 1px 2px 0 #1018280D;
	border-radius: 8px;
	text-align: center;
	padding: 1.2rem;
	font: inherit;
	font-weight: bold;
	border: 1px solid var(--color-fg-brand-primary);
	cursor: pointer;
	width: 100%;
	box-sizing: border-box;
}

.big-blue-button {
	background-color: var(--color-fg-brand-primary);
	color: white;
}

.big-white-button {
	background-color: white;
	color: var(--color-fg-brand-primary);
}

.big-pink-button {
	background-color: var(--color-utility-pink);
	border: 1px solid var(--color-utility-pink);
	color: white;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
